@layer base {
    @font-face {
        font-family:"Arnhem Pro";
        src:url('../fonts/ArnhemPro-Bold.eot');
        src:url('../fonts/ArnhemPro-Bold.eot?#iefix') format("embedded-opentype"),
        url('../fonts/ArnhemPro-Bold.ttf') format("truetype");
        font-weight:700;
        font-style:normal;
    }

}